module.exports = {
  // eslint-disable-next-line no-loss-of-precision
  MaxMintCount: 300,
  BurnCareAmout: 1,
  mintCount: 1,
  mintCost: 1,
  totalGas: 400000,
  NFTCONTRACT_ADDRESS: "0x95BEF55C1a063Cf771eC58154600d62C04422444",
  REWARDTOKEN_ADDRESS: "0x495eea66B0f8b636D441dC6a98d8F5C3D455C4c0",
  STAKINGCONTRACT_ADDRESS: "0x8a56b089d5d9320BD6d0951117E892d1DAE58a98", //old staking
  //STAKINGCONTRACT_ADDRESS: "0x754652CCBC15ed61a6B057c97Da7feD829b22766",
  IMGIPFS_ADDRESS:
    "https://nftstorage.link/ipfs/bafybeiesubhb2223jbpuzbcqyklw7emheoribmsbue7rmppfvhzzuh37mm",
};
